import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { Campanha, CampanhaService } from '../../slidein_api';
import { MenuContext } from '../../layout/context/MenuContext';
import { GestaoMenuItem } from '../../layout/Layout';
import {
  IconAdjustmentsCog,
  IconBus,
  IconCurrencyEuro,
  IconFile,
  IconFileEuro,
  IconHotelService,
  IconList,
  IconLockBolt,
  IconMessageQuestion,
  IconMoneybag,
  IconPackages,
  IconPlus,
  IconReportMoney,
  IconUsers,
  IconUsersGroup,
} from '@tabler/icons-react';
import { msgAPIError } from '../helpers';
import {
  ALL_ALLOW,
  MASTER,
  MASTER_ADMIN,
  MASTER_ADMIN_FINANCE,
  MASTER_FINANCE,
} from '../../components/CheckPermission';

type ContextType = { campanha: Campanha | null; refreshCampanha };

export function CampanhaWrapperView() {
  const navigate = useNavigate();
  const params = useParams();
  const { setMenuItems, setMenuCampanha } = useContext(MenuContext);
  const [campanha, setCampanha] = useState<Campanha | null>(null);
  const [cenas, setCenas] = useState(false);

  useEffect(() => {
    CampanhaService.campanhaRetrieve({ identificador: params.campanhaID }).then(
      (value) => {
        setCampanha(value);
        setMenuCampanha(value);
      },
      (reason) => {
        if (reason.status === 404) {
          navigate('/');
        } else {
          msgAPIError(reason);
        }
      }
    );
  }, [params.campanhaID, cenas]);

  const refreshCampanha = () => {
    setCenas(!cenas);
  };
  const basepath = '/campanha/' + params.campanhaID + '/';

  useEffect(() => {
    const campanhaMenu: GestaoMenuItem[] = [
      {
        label: `Campanha`,
        roles: ALL_ALLOW,
        items: [
          { label: 'Grupos', roles: ALL_ALLOW, icon: <IconList />, to: basepath },
          {
            label: 'Viajantes',
            roles: MASTER_FINANCE,
            icon: <IconUsersGroup />,
            to: basepath + 'viagem',
          },
          {
            label: 'Produtos',
            roles: MASTER_ADMIN_FINANCE,
            icon: <IconPackages />,
            to: basepath + 'produtos',
          },
          {
            label: 'Pagamentos',
            roles: MASTER_FINANCE,
            icon: <IconMoneybag />,
            to: basepath + 'pagamentos',
          },
          {
            label: 'Referências Multibanco',
            roles: MASTER_FINANCE,
            icon: <IconReportMoney />,
            to: basepath + 'ref_mul',
          },
          {
            label: 'Gestão Seguros',
            roles: MASTER_FINANCE,
            indicator: campanha ? campanha.nr_seg_nao_trat : undefined,
            icon: <IconLockBolt />,
            to: basepath + 'gestao_seguros',
          },
          {
            label: 'Notificações',
            roles: MASTER_FINANCE,
            icon: <IconMessageQuestion />,
            to: basepath + 'notifica',
          },
          {
            label: 'Vendas',
            roles: MASTER_FINANCE,
            icon: <IconCurrencyEuro />,
            to: basepath + 'vendas',
          },
          {
            label: 'Balanço Geral',
            roles: MASTER_FINANCE,
            icon: <IconFileEuro />,
            to: basepath + 'balanco',
          },
          { label: 'Documentos', roles: ALL_ALLOW, icon: <IconFile />, to: basepath + 'docs' },
          {
            label: 'Colaboradores',
            roles: MASTER_FINANCE,
            icon: <IconUsers />,
            to: basepath + 'colaboradores',
          },
          {
            label: 'Transportes',
            roles: MASTER_FINANCE,
            icon: <IconBus />,
            to: basepath + 'transporte',
          },
          {
            label: 'Quartos',
            roles: MASTER_FINANCE,
            icon: <IconHotelService />,
            to: basepath + 'quartos',
          },
          {
            label: 'Definições',
            roles: MASTER,
            icon: <IconAdjustmentsCog />,
            to: basepath + 'definicoes',
          },
        ],
      },
      {
        label: `Atalhos Rápidos`,
        roles: MASTER_ADMIN,
        items: [
          {
            label: 'Criar Grupo',
            roles: MASTER_ADMIN,
            icon: <IconPlus />,
            to: basepath + 'grupo/criar',
          },
          {
            label: 'Criar Instituição',
            roles: MASTER_ADMIN,
            icon: <IconPlus />,
            to: '/instituicao/criar',
          },
        ],
      },
    ];
    setMenuItems(campanhaMenu);
  }, [basepath, params.campanhaID, setMenuItems, campanha]);

  return <Outlet context={{ campanha, refreshCampanha }} />;
}

export function useCampanha() {
  return useOutletContext<ContextType>();
}
